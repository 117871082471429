* {
  box-sizing: border-box;
}

body {
  background-color: #eee;
}

input[type='text'],
input[type='email'],
input[type='password'] {
  display: block;
  width: 100%;
  margin: 8px 8px 8px 0;
  padding: 4px;
}

input[type='image'] {
  cursor: pointer;
}
